import { call, takeLatest, all, put } from "redux-saga/effects";
import {
    actionCampaignSaveAsDraft, actionCampaignSaveAsDraftFailed, actionCampaignSaveAsDraftSuccess,
    actionFetchCampaigns,
    actionFetchCampaignsFailed,
    actionFetchCampaignsSuccess,
    actionFetchMediaLibrary,
    actionFetchMediaLibraryFailed,
    actionFetchMediaLibrarySuccess,
    actionFetchScreensList,
    actionFetchScreensListFailed,
    actionFetchScreensListSuccess,
    actionScheduleCampaign, actionScheduleCampaignFailed, actionScheduleCampaignSuccess
} from "./campaigns.reducer";
import { CampaignsService } from "../../services/campaigns/CampaignsService";
import {
    TActionCampaignSaveAsDraft,
    TActionGetCampaignsListPayload,
    TActionGetMediaLibraryPayload,
    TActionScheduleCampaignPayload
} from "./campaigns.types";

function* fetchCampaignsList({payload}: TActionGetCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getCampaigns, {page: payload.page, orgId: payload.orgId})
        yield put(actionFetchCampaignsSuccess({data}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchCampaignsFailed())
    }
}

function* campaignSaveAsDraft({payload}: TActionCampaignSaveAsDraft): any {
    try {
        yield call(CampaignsService.campaignSaveAsDraft, payload);
        yield put(actionCampaignSaveAsDraftSuccess());
    } catch (error) {
        console.log('campaign save as draft error: ', error);
        yield put(actionCampaignSaveAsDraftFailed());
    }
}

function* scheduleCampaign({ payload }: TActionScheduleCampaignPayload): any {
    try {
        const data = yield call(CampaignsService.scheduleCampaign, payload);
        yield put(actionScheduleCampaignSuccess({data}));
        console.log('Data => ', data);
    } catch (error) {
        yield put(actionScheduleCampaignFailed());
        console.log('error: ', error);
    }
}

function* fetchMediaLibrary({payload}: TActionGetMediaLibraryPayload): any{
    try {
        const data = yield call(CampaignsService.getMediaLibrary, payload)                       
        yield put(actionFetchMediaLibrarySuccess({
            data: data.response.mediaList,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMediaLibraryFailed())
    }
}

function* fetchScreensList({payload}: TActionGetCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getScreensList, {page: payload.page})                       
        yield put(actionFetchScreensListSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchScreensListFailed())
    }
}

export function* campaignsWatcher() {
    yield all([
        takeLatest(actionFetchCampaigns.type, fetchCampaignsList),
        takeLatest(actionFetchMediaLibrary.type, fetchMediaLibrary),
        takeLatest(actionFetchScreensList.type, fetchScreensList),
        takeLatest(actionCampaignSaveAsDraft.type, campaignSaveAsDraft),
        takeLatest(actionScheduleCampaign.type, scheduleCampaign)
    ])
}