import { api } from "..";

export const CampaignsService = {
    getCampaigns: (payload: {page: number, orgId?: string}) =>
        api.get(`/public/campaigns/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data),

    getMediaLibrary: (payload: {page: number; orgId: string}) =>
        api.get(`/public/media/list/org/${payload.orgId}/status/UN_VERIFIED/page/${payload.page}`).then((data) => data?.data),

    getScreensList: (payload: {page: number}) =>
        api.get(`/public/screen/state/ALL/city/ALL/page/${payload.page}`).then((data) => data?.data),

    campaignSaveAsDraft: (payload: any) => api.post('/public/campaign/save', payload).then((data) => data?.data),

    scheduleCampaign: (payload: any) =>
        api.post('/public/campaign/schedule', payload).then((data) => data?.data)
}