import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionFetchCampaigns,
  actionFetchMediaLibrary,
  actionSetSelectedMedia,
  resetCampaigns,
  actionFetchScreensList,
  actionSetSelectedScreen,
  actionSetSelectedDates,
  actionSetSelectedSlots,
  actionSetSelectedCampaignType,
    actionAddClusterSlots,
    actionUpdateCreateCampaignPayload,
    actionScheduleCampaign,
    actionScheduleCampaignSuccess,
    actionScheduleCampaignFailed,
    actionCampaignSaveAsDraft
 } from './campaigns.reducer';

export const useCampaignsActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
        actionCampaignSaveAsDraft,
        actionFetchCampaigns,
        actionFetchMediaLibrary,
        actionSetSelectedMedia,
        resetCampaigns,
        actionFetchScreensList,
        actionSetSelectedScreen,
        actionSetSelectedDates,
        actionSetSelectedSlots,
        actionSetSelectedCampaignType,
        actionAddClusterSlots,
        actionUpdateCreateCampaignPayload,
        actionScheduleCampaign,
        actionScheduleCampaignSuccess,
        actionScheduleCampaignFailed
    },
    dispatch
  );
};