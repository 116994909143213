import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./CreateCampaign.module.scss";
import { builderNameSectionMode } from "../../../features/BuilderNameSection/BuilderNameSection.types";
import { routes } from "../../../routing/routes";
import { CreateHeader } from "../../../features/CreateHeader/CreateHeader.component";
import Button from "../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../components/Button/button.types";
import { Stepper } from "../../../components/Stepper/Stepper.component";
import { SelectScreen } from "./SelectScreen/SelectScreenCluster.component";
import { SelectMedia } from "./SelectMedia/SelectMedia.component";
import { useCampaignsActions } from "../../../store/campaigns/useCampaignsActions";
import { ScheduleCampaign } from "./ScheduleCampaign/ScheduleCampaign.component";
import { useCampaignsSelector } from "../../../store/campaigns/useCampaignsSelector";
import {useClustersSelector} from "../../../store/cluster/useClusterSelector";
import {useLoginSelector} from "../../../store/login/useLoginSelectors";
import {useClustersActions} from "../../../store/cluster/useClusterActions";
import {requestStatusEnum} from "../../../store/store.types";

export const CreateCampaign: React.FC = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");

  const { actionResetSelectedCluster } = useClustersActions();
  const { resetCampaigns, actionUpdateCreateCampaignPayload, actionScheduleCampaign, actionCampaignSaveAsDraft} = useCampaignsActions();
  const {
    selectedMedia,
    selectedScreens,
    selectedDates,
    selectedSlots,
      selectedClusterSlots,
    SelectedCampaignType,
      selectedMediaLength,
      createCampaignPayload,
    campaignSaveAsDraftStatus
  } = useCampaignsSelector();

  const { selectedClusters } = useClustersSelector();
  const { userData } = useLoginSelector();

  console.log('campaignSaveAsDraftStatus => ', campaignSaveAsDraftStatus);

  useEffect(() => {
    actionUpdateCreateCampaignPayload({
      userId: userData?.userId,
      orgId: userData?.ordId
    });
    return () => {
      resetCampaigns();
      actionResetSelectedCluster();
    };
  }, []);

  useEffect(() => {
    if(campaignSaveAsDraftStatus === requestStatusEnum.SUCCESS) navigate(routes().campaign);
  }, [campaignSaveAsDraftStatus]);

  useEffect(() => {
    if (inputValue) {
      actionUpdateCreateCampaignPayload({
        name: inputValue,
      });
    }
  }, [inputValue]);

  useEffect(() => {
    if (selectedMedia) {
      actionUpdateCreateCampaignPayload({
        mediaId: selectedMedia,
        mediaLengthInSec: selectedMediaLength,
      });
    }
  }, [selectedMedia]);

  useEffect(() => {
    if (selectedClusters) {
      actionUpdateCreateCampaignPayload({
        clusters: selectedClusters,
      });
    }
  }, [selectedClusters]);

  useEffect(() => {
    if (selectedClusterSlots) {
      actionUpdateCreateCampaignPayload({
        slots: selectedClusterSlots
      });
      actionUpdateCreateCampaignPayload({
        startTime: selectedClusterSlots[0],
        endTime: selectedClusterSlots[selectedClusterSlots?.length - 1]
      });
    }
  }, [selectedClusterSlots]);

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const onCloseClick = () => {
    localStorage.removeItem('SelectedCampaignType');
    navigate(routes().campaign);
  };

  const onStepClick = (step: number) => {
    setCurrentStep(step);
  };

  const onNextClick = () => {
    if (currentStep < 3) setCurrentStep(currentStep + 1)
    else {
      actionScheduleCampaign(createCampaignPayload);
    };
  };
  const onBackClick = () => {
    if (currentStep === 1) onCloseClick();
    else setCurrentStep(currentStep - 1);
  };
  const onDraftSave = () => {
    actionCampaignSaveAsDraft(createCampaignPayload);
  };

  const validateSaveAsDraft = useMemo(() => {
    if (!inputValue) return false;
    switch (currentStep) {
      case 1:
        return !!selectedMedia;
      default:
        return true;
    }
  },[inputValue, selectedMedia])

  const validateScreen = useMemo(() => {
    if (!inputValue) return false;
    switch (currentStep) {
      case 1:
        return !!selectedMedia;
      case 2:
        return SelectedCampaignType === 'myScreens' ? !!selectedScreens?.length : !!selectedClusters?.length;
      case 3:
        return (!!selectedDates.length && !!selectedSlots.length) || !!selectedClusterSlots?.length;
      default:
        return true;
    }
  }, [inputValue,currentStep,selectedMedia,selectedScreens, selectedClusters, selectedDates, selectedSlots , selectedClusterSlots]);

  const steps = [
    { label: "Setup", component: SelectMedia },
    {
        label: SelectedCampaignType === 'myScreens' ? 'Select Screens' : 'Select Cluster',
        component: SelectScreen 
    },
    { label: "Schedule", component: ScheduleCampaign },
];


  const CurrentComponent = useMemo(() => {
    return steps?.[currentStep - 1].component;
  }, [currentStep]);

  const footer = (
    <div className={styles.footer}>
      <Button
        variant={ButtonVariantEnum.secondary}
        text="Back"
        onClick={onBackClick}
        height={"32px"}
        width={"78px"}
      />
      <Button
          variant={ButtonVariantEnum.secondary}
          text="Save As Draft"
          onClick={onDraftSave}
          height={"32px"}
          width={"120px"}
          disabled={!validateSaveAsDraft}
      />
      <Button
        variant={ButtonVariantEnum.primary}
        text={currentStep === 3 ? "Publish" : "Next"}
        onClick={onNextClick}
        height={"32px"}
        width={"78px"}
        disabled={!validateScreen}
      />
    </div>
  );
  
  return (
        <div>
          <CreateHeader
            headerText="Create New Campaign"
            name={inputValue}
            setName={onInputChange}
            nameFieldPlaceholder={"Enter Campaign Name"}
            onCloseClick={onCloseClick}
            mode={builderNameSectionMode.CREATE}
            footer={footer}
          />
          <div className={styles.currentCompWrapper}>
            <Stepper
              steps={steps}
              currentStep={currentStep}
              // onStepClick={onStepClick}
            />
            <CurrentComponent />
          </div>
        </div>
  );
};
